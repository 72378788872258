<script lang="ts">
export default {
  name: 'app-contact-form-modal'
};
</script>

<script lang="ts" setup>
import { useModalStore } from '~/store/modal';
import { useSpinnerStore } from '@/store/spinner';
import { useBrandsInfoStore } from '~/store/brandsInfo';

const brandsInfoStore= useBrandsInfoStore();
const modalStore = useModalStore();
const spinnerStore = useSpinnerStore();

const { hideModal,modal } = modalStore;
const marca = useRoute().params.marca;
const customInfo= ref(await brandsInfoStore.getBrandCustomisation(marca));

const uuid= useId();

let fields = {
    nombre: {
        value: '',
        validations: ['required', 'string']
    },
    telefono: {
        value: '',
        validations: ['required', 'phone']
    },
    email: {
      value: '',
      validations: ['required', 'email']
    },
    departamento_sede: {
        value: '',
        validations: ['required']
    },
    mensaje: {
        value: '',
        validations: ['required']
    },
    acepta_gdpr_1: {
        value: false,
        validations: ['required']
    },
    acepta_gdpr_2: {
        value: false,
        validations: []
    }
};


const fieldLocation = {
  instalacion: {
    value: '',
    validations: ['required']
  }
};

if(modal.props.locations?.length>0){
    fields=Object.assign(fields, fieldLocation);
}

const { formData, formStates, validateData } = useFormsMa(fields);

const cities = (modal.props.locations || []).map(item => item.ciudad);


/**
 * Valida datos del formulario y si es correcto los envía
 */
 async function sendForm() {
    const router = useRouter();
    const url = useRequestURL();
    const { $api } = useNuxtApp();
    const { isOk, formData } = validateData();
    if (isOk) {
        formData.append('form', 'contacto');
        formData.append('path', url.href);
        formData.append('pagina',`/${marca}`);
        formData.append('tipo_contacto',' web_callme');

        if(cities && cities.length > 0) {

          const filteredLocation = modal.props.locations.filter(item => item.ciudad === formData.get('instalacion'))[0];
          
          formData.append('id_concesionarios', filteredLocation.id_concesionario);
          formData.append('id_sedes', filteredLocation.id_sede);

        }
        spinnerStore.showSpinner();
        
        const { data, status, error } = await $api.forms.store(formData);
        spinnerStore.hideSpinner();

        if (error.value) {
          throw createError({ statusCode: 500, statusMessage: error.value?.data?.message || error.value.message })
            return;
        }
        hideModal();
        //router.push('/gracias');
        await navigateTo({
          path: `/${marca}/gracias/`,
          query: {
            form:'form_contacto',
          }
        });
    }
}

</script>

<template lang="pug">
.contact-form-modal.modal-custom__inner
  .contact-form-modal__header 
    span.icon-times(@click="hideModal()")
  hr.mt-3.mt-lg-5
  .contact-form-modal__body
    p.h3.mb-3 CONTACTO  
    p.h4 Teléfono de atención al cliente. Llámanos
    a.large-text(:href="`tel:+34${customInfo.contacto_telefono?.replace(/ /g,'')}`") {{ customInfo.contacto_telefono}}
    router-link.btn-brand-tertiary.btn-brand-tertiary--secondary.mt-3(:to="`/${marca}/instalaciones`" @click="hideModal()") 
      span.text-uppercase.text-decoration-underline.line-underline-adjust Ver todas las instalaciones
    hr.dashed
    //-p.h4 ¿Necesitas ayudas? Escríbenos
      a.large-text-mail.text--brand-secondary(:href="`mailto:${customInfo.contacto_email}`") {{ customInfo.contacto_email }}
      hr.dashed
    p.h4 ¿Necesitas ayuda?
      br
      | Deja tus datos y nos pondremos en contacto contigo
    form.mt-4.floatingForm
      .form-floating.mb-3
        input.form-control.form-control--border-primary(
          type="text", 
          :id="`nombre${uuid}`",
          placeholder="Nombre",
          v-model="formData.nombre")
        label(:for="`nombre${uuid}`") Nombre
        span.text-danger(v-if="formStates.nombre") {{ formStates.nombre }}
      .form-floating.mb-3
        input.form-control.form-control--border-primary(
          type="tel", 
          :id="`telefono${uuid}`",
          placeholder="Teléfono",
          v-model="formData.telefono")
        label(:for="`telefono${uuid}`") Teléfono
        span.text-danger(v-if="formStates.telefono") {{ formStates.telefono }}
      .form-floating.mb-3
        input.form-control.form-control--border-primary(
          type="email", 
          :id="`email${uuid}`",
          placeholder="Email",
          v-model="formData.email")
        label(:for="`email${uuid}`") Email
        span.text-danger(v-if="formStates.email") {{ formStates.email }}
        
      .form-floating.mb-3(v-if="cities && cities.length > 0")
        select.form-select.form-control--border-primary(
          :id="`location${uuid}`",
          v-model="formData.instalacion"
        )
          option(value="") Elige Instalación
          option(v-for="(location, index) in cities" :key="index" :value="location") {{ location }}
        label(:for="`location${uuid}`") Instalación
        span.d-block.text-danger(v-if="formStates.instalacion") {{ formStates.instalacion }}
      .form-floating.mb-3
        select.form-select.form-control--border-primary(
          :id="`department${uuid}`",
          v-model="formData.departamento_sede"
        )
            option(value="") Elige Departamento
            option(value="ventas") Ventas
            option(value="Posventa") Posventa
            option(value="Seguros") Seguros
            option(value="Empresas") Empresas
        label(:for="`department${uuid}`") Departamento
        span.d-block.text-danger(v-if="formStates.departamento_sede") {{ formStates.departamento_sede }}
      .form-floating.mb-3
        textarea.form-control.form-control--border-primary(
          :id="`message${uuid}`", 
          placeholder="Mensaje"
          style="height: 100px",
          v-model="formData.mensaje"
          )
        label(:for="`message${uuid}`") Comentarios
        span.text-danger(v-if="formStates.mensaje") {{ formStates.mensaje }}
      .form-check.mb-1
        input.form-check-input(
          type="checkbox", 
          :id="`acceptances${uuid}`",
          v-model="formData.acepta_gdpr_1")
        label(:for="`acceptances${uuid}`") He leído y acepto los 
          nuxt-link.termsLink(target="_blank", :to="`/${marca}/politica-de-privacidad/`") términos y condiciones de la política de privacidad.
        span.d-block.text-danger(v-if="formStates.acepta_gdpr_1") {{ formStates.acepta_gdpr_1 }}
      .form-check
        input.form-check-input(
          type="checkbox", 
          :id="`consent${uuid}`",
          v-model="formData.acepta_gdpr_2"
        )
        label(:for="`consent${uuid}`") Doy mi consentimiento para el tratamiento de mis datos personales con fines de marketing y comerciales
      button.btn-brand.btn-brand--cta.btn-brand--large.mt-4(@click.prevent="sendForm()") Enviar
</template>

<style lang="scss">
@import 'index';
</style>