import { reactive } from 'vue';
import { defineStore } from 'pinia';

export const useBrandsFooterStore = defineStore('brand-footer-store', () => {
  const { $api } = useNuxtApp();

  const footer= reactive({
     activeBrand : '',
     footerInfo : {}
  });

  const {activeBrand,footerInfo } = toRefs(footer);

  const setActiveBrand= async (slug: string)=>{

 
    if(slug !== activeBrand.value){
      activeBrand.value=slug;
      const { data, error } = await $api.brands.show(activeBrand.value,{
        pick:['payload']
      });

      if (error.value) {
        throw createError({ statusCode: 500, statusMessage: error.value?.data?.message || error.value.message })
      }

      footerInfo.value= data.value.payload.footer;
    }

    return footer;
  };



  return { 
    footer,
    setActiveBrand,
  };
});