<!-- Name -->
<script lang="ts">
export default {
  name: 'app-footer-brand'
};
</script>

<script lang="ts" setup>

import { useBrandsInfoStore } from '~/store/brandsInfo'; 
import { useBrandsFooterStore } from '~/store/brandsFooter'; 

const route = useRoute();

const brandsInfoStore= useBrandsInfoStore();
const brandsFooterStore= useBrandsFooterStore();
const brand = brandsInfoStore.getBrand(route.params.marca);
const { footer } = storeToRefs(brandsFooterStore);

const customInfo= ref(await brandsInfoStore.getBrandCustomisation(route.params.marca));


watch(
  () => route.params.marca, 
  () => {
    brandsFooterStore.setActiveBrand(route.params.marca);
  }
);


await brandsFooterStore.setActiveBrand(route.params.marca);




</script>

<template lang="pug">
.app-footer-brand
  .container
    .row.justify-content-between.align-items-center 
      .col-lg-4.my-4.my-lg-0.d-lg-flex.justify-content-between.gap-5
        .app-footer-brand__middle.first(v-if="footer.footerInfo.posicion1.items")
          p.h5.text-center.text-lg-start(v-if="footer.footerInfo.posicion1.titulo") {{ footer.footerInfo.posicion1.titulo}}
          ul.mt-3.mt-lg-0(v-if="footer.footerInfo.posicion1.items")
            li(v-for="(item,index) in footer.footerInfo.posicion1.items" :key="index" :class="{'mb-lg-2':!loopsUtils(index,footer.footerInfo.posicion1.items)}")
              nuxt-link(
                v-if="!item.url_externa",
                :to="item.ruta",
                :target="item.target_blank ? '_blank' : '_self'"
              ) 
                span.icon-poligon-right.text--brand-secondary
                span {{item.texto}}
              a(
                v-else,
                :href="item.ruta", 
                :target="item.target_blank ? '_blank' : '_self'", 
                rel="noopener"
              ) 
                span.icon-poligon-right.text--brand-secondary
                span {{item.texto}}
        .app-footer-brand__middle.mt-4.mt-lg-0(v-if="footer.footerInfo.posicion2.items")
          p.h5.text-center.text-lg-start(v-if="footer.footerInfo.posicion2.titulo") {{ footer.footerInfo.posicion2.titulo}} 
          ul.mt-3.mt-lg-0(v-if="footer.footerInfo.posicion2.items")
            li(v-for="(item,index) in footer.footerInfo.posicion2.items" :key="index" :class="{'mb-lg-2':!loopsUtils(index,footer.footerInfo.posicion2.items)}")
              nuxt-link(
                v-if="!item.url_externa",
                :to="item.ruta",
                :target="item.target_blank ? '_blank' : '_self'"
              ) 
                span.icon-poligon-right.text--brand-secondary
                span {{item.texto}}
              a(
                v-else,
                :href="item.ruta", 
                :target="item.target_blank ? '_blank' : '_self'", 
                rel="noopener"
              ) 
                span.icon-poligon-right.text--brand-secondary
                span {{item.texto}}


      .col-lg-2
        .app-footer-brand__logo
          nuxt-link(:to="`/${route.params.marca}/`")
            span.icon(:class="`icon-${route.params.marca}`")
            img.img-fluid(v-if="route.params.marca !=='marcos-cycles'" src="~assets/images/marcos-logo-brand-white.png", 
            alt="alt",
            width="128")
      .col-lg-4.d-lg-flex.justify-content-between.gap-5 
        .app-footer-brand__middle(v-if="footer.footerInfo.posicion3.items")
          p.h5.text-center.text-lg-start(v-if="footer.footerInfo.posicion3.titulo") {{ footer.footerInfo.posicion3.titulo}}
          ul.mt-3.mt-lg-0(v-if="footer.footerInfo.posicion3.items")
            li(v-for="(item,index) in footer.footerInfo.posicion3.items" :key="index" :class="{'mb-lg-2':!loopsUtils(index,footer.footerInfo.posicion3.items)}")
              nuxt-link(
                v-if="!item.url_externa",
                :to="item.ruta",
                :target="item.target_blank ? '_blank' : '_self'"
              ) 
                span.icon-poligon-right.text--brand-secondary
                span {{item.texto}}
              a(
                v-else,
                :href="item.ruta", 
                :target="item.target_blank ? '_blank' : '_self'", 
                rel="noopener"
              ) 
                span.icon-poligon-right.text--brand-secondary
                span {{item.texto}}
                

        .app-footer-brand__right.mt-4.mt-lg-0
          span REDES SOCIALES
          ul
            li(v-if="!!brand.url_facebook")
              a(
                :href="brand.url_facebook", 
                target="_blank", 
                rel="noopener") 
                span.icon-facebook
            li(v-if="!!brand.url_instagram")
              a(
                :href="brand.url_instagram", 
                target="_blank", 
                rel="noopener") 
                span.icon-instagram
            li(v-if="!!brand.url_youtube")
              a(
                :href="brand.url_youtube", 
                target="_blank", 
                rel="noopener") 
                span.icon-youtube
            li(v-if="!!brand.url_linkedin")
              a(
                :href="brand.url_linkedin", 
                target="_blank", 
                rel="noopener") 
                span.icon-linkedin
                
            li(v-if="!!brand.url_tiktok")
              a(
                :href="brand.url_tiktok", 
                target="_blank", 
                rel="noopener") 
                span.icon-tiktok
    hr
  .app-footer-brand__bottom
    .container
      .row.flex-column-reverse.flex-lg-row
        .col-lg-6.text-center.text-lg-start 
          small.d-block Powered By 
            nuxt-link(to="https://www.tilomotion.com/" target="_blank").app-footer-brand__bottom-tilo Tilo Motion
          small &copy;  2024 Marcos {{route.params.marca === 'marcos-cycles' ? 'Cycles': 'Automoción'}} - Todos los derechos reservados
        .col-lg-6 
          ul
            li(v-if="customInfo.secciones_opcionales && customInfo.secciones_opcionales.sostenibilidad")
              nuxt-link(:to="`/${route.params.marca}/sostenibilidad/`") Sostenibilidad
            li
              nuxt-link(:to="`/${route.params.marca}/politica-de-cookies/`") Política de cookies
            li
              nuxt-link(:to="`/${route.params.marca}/aviso-legal/`") Aviso legal
            li 
             nuxt-link(:to="`/${route.params.marca}/politica-de-privacidad/`") Política de privacidad
            li(v-if="customInfo.secciones_opcionales && customInfo.secciones_opcionales.te_escuchamos")
             nuxt-link(:to="`/${route.params.marca}/te-escuchamos/`") Te escuchamos
          hr.d-lg-none
</template>

<style lang="scss">
@import 'index';
</style>